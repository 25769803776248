import BasicLayout from "main/layouts/BasicLayout/BasicLayout";
import { useParams } from "react-router-dom";
import MenuItemReviewForm from 'main/components/MenuItemReview/MenuItemReviewForm';
import { Navigate } from 'react-router-dom'
import { useBackend, useBackendMutation } from "main/utils/useBackend";
import { toast } from "react-toastify";

export default function MenuItemReviewEditPage({storybook=false}){
    let { id } = useParams();

    const { data: menuItemReview, _error, _status } =
        useBackend(
            // Stryker disable next-line all : don't test internal caching of React Query
            [`/api/menuitemreview?id=${id}`],
            {  // Stryker disable next-line all : GET is the default, so mutating this to "" doesn't introduce a bug
                method: "GET",
                url: `/api/menuitemreview`,
                params: {
                    id
                }
            }
        );

    const objectToAxiosPutParams = (menuItemReview) => ({
        url: "/api/menuitemreview",
        method: "PUT",
        params: {
            id: menuItemReview.id,
        },
        data: {
            itemId: menuItemReview.itemId,
            reviewerEmail: menuItemReview.reviewerEmail,
            stars: menuItemReview.stars,
            dateReviewed: menuItemReview.dateReviewed,
            comment: menuItemReview.comment
        }
    });

    const onSuccess = (menuItemReview) => {
        toast(`Menu Item Review Updated - id: ${menuItemReview.id} - itemId: ${menuItemReview.itemId} - reviewerEmail: ${menuItemReview.reviewerEmail} - stars: ${menuItemReview.stars} - dateReviewed: ${menuItemReview.dateReviewed} - comment: ${menuItemReview.comment}`);
    }

    const mutation = useBackendMutation(
        objectToAxiosPutParams,
        { onSuccess },
        // Stryker disable next-line all : hard to set up test for caching
        [`/api/menuitemreview?id=${id}`]
    );

    const { isSuccess } = mutation

    const onSubmit = async (data) => {
        mutation.mutate(data);
    }

    if (isSuccess && !storybook) {
        return <Navigate to="/menuitemreview" />
    }

    return (
        <BasicLayout>
            <div className="pt-2">
                <h1>Edit Menu Item Review</h1>
                {
                    menuItemReview && <MenuItemReviewForm submitAction={onSubmit} buttonLabel={"Update"} initialContents={menuItemReview} />
                }
            </div>
        </BasicLayout>
    )

}
// comment so i can commit